header {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
}
.OrderHistoryFooter {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
