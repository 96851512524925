.App {
  text-align: center;
}

.btn:hover{
  background-color: rgba(0, 0, 0, 0.297);
  color:white;
  border: 2px solid blue;


}
button:hover{
  background-color: rgba(0, 0, 0, 0.297);
  color:white;
  border: 2px solid blue;


}

.widget_frame_base{
  min-width: 100px !important;
  max-width: 1000px !important;
}

#txtmeLivechatCloseBtn{
  background: #4bd74b;
}
#txtmeFrameWrapper, #txtmeButtonWrapper{
  bottom:95px !important;
}


