textarea:focus,
.inputt:focus {
  outline: none;
}
@media only screen and (min-width: 280px) and (max-width: 800px) {
    .bk inputt {
      padding: 0%;
      width: 100%;
    }
  }
  .inputt {
    border: 0px solid;
  
    font-weight: 600;
  }